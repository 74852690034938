@import "../../BaseScss/Base.scss";
.footer
{
    display: grid;
    font-size: 10px;
    border-top: 2px solid $border-color;
    box-shadow: rgba(0, 0, 0, 0.3) 0px -5px 30px;
    padding: 10px 4%;
}
.one {
  grid-column: 1;
  grid-row: 1;
}
.two {
  grid-column: 1;
  grid-row: 2;
}
.three {
  grid-column: 1;
  grid-row: 3;
}
.four {
  grid-column: 2;
  grid-row: 1;
}
.five {
  grid-column: 2;
  grid-row: 2;
}
.six {
  grid-column: 2;
  grid-row: 3;
}
.seven{
  grid-column: 3;
  grid-row: 1;
}
.eight{
  grid-column: 3;
  grid-row: 2;
}
.nine{
  grid-column: 3;
  grid-row: 3;
}
@media (min-width: 900px){
    .footer{
      display: grid;
      padding: 10px 20%;
      gap: 0px;
      font-size: 15px;
    }
    .one {
        grid-column: 1;
        grid-row: 1;
      }
      .two {
        grid-column: 1;
        grid-row: 2;
      }
      .three {
        grid-column: 1;
        grid-row: 3;
      }
      .four {
        grid-column: 2;
        grid-row: 1;
      }
      .five {
        grid-column: 2;
        grid-row: 2;
      }
      .six {
        grid-column: 2;
        grid-row: 3;
      }
      .seven{
        grid-column: 3;
        grid-row: 1;
      }
      .eight{
        grid-column: 3;
        grid-row: 2;
      }
      .nine{
        grid-column: 3;
        grid-row: 3;
      }
}