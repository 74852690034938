$green-color-dark: #2F873C;
$green-color-light: #339241;
$red-color-dark: #C20F06;
$red-color-light: #cc231a;
$orange-color-dark: #FF6600;
$orange-color-light: #ee7627;

$border-color: #545659;
$navbar-color: #2b7535;

@mixin displaygrid($repeatcount, $repeattrack) {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat($repeatcount, $repeattrack);
    grid-auto-rows: minmax(auto auto);
}

@mixin background($url, $size, $repeat)
{
    background-image: url($url);
    background-size: $size;
    background-repeat: $repeat;
    background-position: top;
}

@mixin grid-column-row($column-start, $column-end, $row-start, $row-end) {
    grid-column-start: $column-start;
    grid-column-end: $column-end;
    grid-row-start: $row-start;
    grid-row-end: $row-end;
}

@mixin img-company($max-width)
{
    width: 100%;
    max-width: $max-width;
    height: auto;
}
.red-background {
    background: linear-gradient(to right, $red-color-dark, $red-color-light);
}
.green-background {
    background: linear-gradient(to right, $green-color-dark, $green-color-light);
}
.orange-background {
    background: linear-gradient(to right, $orange-color-dark, $orange-color-light);
}
.img-company-default
{
   @include img-company(350px)
}
.img-company-small
{
    @include img-company(200px)
}
@media (max-width: 900px) {
    
    .fontSize-9
    {
        font-size: 8px;
    }
    .fontSize-12
    {
        font-size: 12px;
    }
}

.border {
    border: 2px solid black;
}