.complite-steps{
    .default-p{
        text-align: start;
    }
}
.default-p{
    font-size: 3vw;
}
.like-h3
{
    font-size: 3vw;
    font-weight: 600;
}
.img-complite{
    height: auto;
    max-width: 6vw;
}

.block-fact
{
    border: 2px solid black;
    background-color: white;
    border-radius: 10px;
    margin: 15px 3px;
    padding: 0px 0px;
    width: 30%;
    text-align: center;
    .icon{
        font-size: 7vw;
    }
    .default-p{
        word-wrap: break-word;
    }
}
@media(min-width: 900px){

    .like-h3{
        font-size: 28px;
    }
    .img-complite{
        height: auto;
        max-width: 50px;
    }
    .default-p{
        font-size: 16px;
    }
    .block-fact{
        height: auto;
        width: 25%;
        .default-p{
            font-size: 28px;
            font-weight: 600;
        }
        .icon{
            font-size: 50px;
            font-weight: 600;
        }
    }
}