@import "../BaseScss/Base.scss";

dialog {
    border: 2px solid $green-color-dark;
}

.card-size {
    width: 20rem;
}

.modal-message {
    display: flex;
    flex-direction: column;

    label {
        padding: 0.2rem 0 0.2rem 0;
        text-align: start;
    }

    .modal-message-input {
        border: 2px solid $green-color-dark;
        background-color: #eee;
        vertical-align: top;
        outline: none;
        padding: 0;
        height: 40px;
        line-height: 40px;
        text-indent: 10px;
        display: inline-block;
        width: 100%;
        box-sizing: border-box;
        border: 2px solid #ddd;
        font-size: 14px;
        border-radius: 3px;
    }
    .modal-message-input[type=text] {
        height: 2.5rem;
    }
 .modal-message-input[type=number] {
        height: 2.5rem;
        width: 30%;
        margin: 0 1rem 0 1rem;
        text-align: center;
    }
    .modal-message-input[type=submit] {
        margin: 2rem auto 2rem auto;
    }
}