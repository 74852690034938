@import "../../../BaseScss/Base.scss";

.form_block {
    border: 10px solid rgb(65, 148, 89);
}

.form_block::after {
    content: "";
    position: absolute;
    width: 49%;
    height: 72.5%;
    top: 28%;
    background: rgb(150, 150, 150, 0.9) url("../../../../Images/Gif/loading.gif") center / 50px no-repeat;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease 0s;
}

.form_block._sending::after {
    opacity: 1;
    visibility: visible;
}

.form_field {
    padding: 10px;
    max-width: min-content;
    margin: auto;

}

.form_label {
    display: block;
    width: 250px;
}

.form_input {
    width: 250px;
    height: 40px;
    margin-bottom: 10px;
    border-radius: 10px;
}

.form_input:focus {
    box-shadow: 0 0 10px 5px green;
}

.form_input._error {
    box-shadow: 0 0 10px 5px red;
}

.form_input._message {
    height: 150px;
}