@import "../BaseScss/Base.scss";

.navbar-body {
    margin: 3rem;
    //border: 2px solid black;
    background: linear-gradient(to right, $green-color-dark, $green-color-light);
}
.nav-item {
    padding: 0 2rem;
    padding-top: 0.5rem;

    figcaption{
        color: white;
    }
}