@import "../BaseScss/Base.scss";
.company-header-block{
    display: inline-block;
    
}
.company-header-description{
    float: left;
    vertical-align: bottom;
    margin-right: 100px;
}
.company-header-logo{
    float: right;
    vertical-align: middle;
}
.list-bg
{
    background-color: #545659;
    border: 2px solid black;
    border-radius: 20px;
}
.list-item
{
    border: 2px solid black;
    border-radius: 50px;

}
.row-facts
{
    display: flex;
    border: 2px solid black;
    border-radius: 20px;
}