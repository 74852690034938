@import "../../BaseScss/Base.scss";


#item {
    padding: 0 10px 10px 10px;
    text-align: center;
}
#isHover{
    li:hover
    {
        box-shadow: 5px 5px 5px black;
    }
    li:active
    {
        box-shadow: inset -2px -2px 3px rgba(0, 0, 0, 0.6), inset 2px 2px 3px rgba(0, 0, 0, 0.6);
    }
    
}
.group-border
{
    border: 2px solid black;
    background: linear-gradient(to right, $green-color-dark, $green-color-light);
    border-radius: 1px 100px 1px;

    p{
        background: none;
        color: white;
        font-size: 18px;
        border-bottom: 2px solid black;
    }
}
.white-border
{
    border: 1px white;
    background: white;
    border-radius: 5px;
    width: 75%;
    margin: 10px auto 10px auto;
}
@media (min-width: 900px) {
    .groupGrid {
        @include displaygrid(3, 1fr);
    }
    .group_company_builder {
        @include grid-column-row(1, 4, 1, 3);
    }
    .uuk_coal{
        @include grid-column-row(1, 1, 3, 4);
    }
    .usk_builder {
        @include grid-column-row(2, 2, 5, 5);
    }
    .uzk_stone {
        @include grid-column-row(3, 3, 3, 4);
    }
}
.group_company_builder {
    img{
        @include img-company(550px)
    }
}
.uuk_coal{
    img{
        @include img-company(320px)
    }
}
.usk_builder {
    img{
        @include img-company(450px)
    }
}
.uzk_stone {
    img{
        @include img-company(350px)
    }
}